<template>
    <div class="beer-competition page">
          <b-container >
            <b-row>
 
                <b-col cols=12 lg="4">
                    <div class="beer-competition--img-block">   
                        <img src="@/assets/images/brand/nyertes.png" alt="Sörverseny">
                    
                    </div>
                </b-col>
               
                <b-col cols=12 lg="6">
                    <div class="beer-competition--txt-block"> 
                        <div class="beer-competition-txt-bg">
                             <img src="@/assets/images/brand/bg-graphic.png" alt="Sörverseny">
                        </div>
                        <h1>Megvan a II. Soproni Sörverseny nyertese! </h1>
                        <p>A zsűri döntése alapján <span>Cserjés László</span> főzte a legjobb hop lagert, ami majd a közös munka után az Óvatos Duhaj család tagja lesz. Gratulálunk!</p>
                        <h2>Gratulálunk!</h2>
                    </div>
                </b-col>
            
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    mounted(){
        this.setMetaTags({
            title: 'Soproni - Sörverseny', 
            desc: 'A Soproni tavaly már megcsinálta, és idén meghirdeti a második Soproni Sörversenyt, amelyen bárki bizonyíthat.', 
            keywords: '', 
            ogType: 'website', 
            ogTitle: 'Soproni - Sörverseny',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:'A Soproni tavaly már megcsinálta, és idén meghirdeti a második Soproni Sörversenyt, amelyen bárki bizonyíthat.'
        })
    }
}
</script>